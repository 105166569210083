import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'baseui/skeleton'
import { Block } from 'baseui/block'
import isNil from 'lodash/isNil'

import { useHorseHand } from '@hooks/Horse/useHorseHand'

const Height = ({ id }) => {
  const { isLoading, data: { hand } = {} } = useHorseHand(id)

  if (isLoading)
    return (
      <Block marginBottom="scale100">
        <Skeleton height="24px" width="30%" animation />
      </Block>
    )
  if (isNil(hand?.handInch))
    return (
      <Block marginBottom="scale100">
        <Skeleton height="24px" width="30%" />
      </Block>
    )

  return <span>{hand?.handInch}</span>
}

Height.propTypes = {
  id: PropTypes.number.isRequired
}

export default Height
