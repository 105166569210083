import { useQuery } from 'react-query'
import axios from 'axios'
import { getErrorMessage } from '@src/lib/getErrorMessage'
import toInteger from 'lodash/toInteger'

export const useHorsePrice = (horseId) => {
  return useQuery(['horse', toInteger(horseId), 'price'], async () => {
    try {
      const response = await axios.get(`/api/horses/${horseId}/price`)
      return response.data
    } catch (error) {
      throw new Error(getErrorMessage(error))
    }
  })
}
