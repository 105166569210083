import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'

const Tab = ({ children, emphasis, inline }) => {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        paddingTop: theme.sizing.scale200,
        paddingBottom: theme.sizing.scale200,
        marginRight: theme.sizing.scale100,
        display: inline ? 'inline-block' : 'block'
      })}
    >
      <span
        className={css({
          borderWidth: emphasis ? '2px' : '1px',
          borderStyle: 'solid',
          borderColor: emphasis ? 'hsla(0, 0%, 0%, 0.2)' : 'hsla(0, 0%, 0%, 0.15)',
          paddingTop: theme.sizing.scale100,
          paddingBottom: theme.sizing.scale100,
          paddingLeft: theme.sizing.scale500,
          paddingRight: theme.sizing.scale500,
          fontSize: emphasis ? '14px' : '12px',
          fontWeight: 'normal',
          lineHeight: emphasis ? '22px' : '20px',
          borderTopRightRadius: theme.sizing.scale1200,
          borderTopLeftRadius: theme.sizing.scale1200,
          borderBottomLeftRadius: theme.sizing.scale1200,
          borderBottomRightRadius: theme.sizing.scale1200
        })}
      >
        {children}
      </span>
    </div>
  )
}

Tab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  emphasis: PropTypes.bool,
  inline: PropTypes.bool
}

Tab.defaultProps = {
  emphasis: true,
  inline: false
}

export default Tab
