import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'baseui/skeleton'
import isUndefined from 'lodash/isUndefined'
import { Block } from 'baseui/block'

import { useHorseBreed } from '@hooks/Horse/useHorseBreed'
import Tab from '@global/Tab'

const Breed = ({ id }) => {
  const { isLoading, data: { breed } = {} } = useHorseBreed(id)

  if (isLoading)
    return (
      <Block marginTop="scale100" marginBottom="scale100">
        <Skeleton height="30px" width="150px" animation />
      </Block>
    )
  if (isUndefined(breed?.name))
    return (
      <Block marginTop="scale100" marginBottom="scale100">
        <Skeleton height="30px" width="150px" />
      </Block>
    )

  return <Tab emphasis={false}>{breed?.name}</Tab>
}

Breed.propTypes = {
  id: PropTypes.number.isRequired
}

export default Breed
