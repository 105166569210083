import React from 'react'
import PropTypes from 'prop-types'
import { IoArrowForwardCircleOutline } from 'react-icons/io5'
import { useRouter } from 'next/router'
import { Button, KIND, SHAPE } from 'baseui/button'

const Go = ({ id }) => {
  const { push } = useRouter()
  return (
    <div>
      <Button kind={KIND.secondary} shape={SHAPE.circle} onClick={() => push(`/show/${id}`)}>
        <IoArrowForwardCircleOutline size={30} />
      </Button>
    </div>
  )
}

Go.propTypes = {
  id: PropTypes.number.isRequired
}

export default Go
