import * as React from 'react'
import { Card } from 'baseui/card'
import { useStyletron } from 'baseui'
import Link from 'next/link'

import Image from '@components/Card/shared/Image'
import Title from '@components/Card/shared/Title'
import About from '@components/Card/shared/About'
import Breed from '@components/Card/shared/Breed'
import Price from '@components/Card/shared/Price'
import Height from '@components/Card/shared/Height'
import Age from '@components/Card/shared/Age'
import Avatar from '@components/Card/shared/Avatar'
import Name from '@components/Card/shared/Name'
import Go from '@components/Card/shared/Go'

import { Flex, FlexItem } from '@global/Flex'
import { HorizontalRule as HR } from '@global/HorizontalRule'

interface Props {
  id: number
}

function Standard({ id }: Props) {
  const [css, theme] = useStyletron()

  return (
    <Card>
      <Link href={`/show/${id}`} passHref>
        <a>
          <Image id={id} />
        </a>
      </Link>
      <Breed id={id} />
      <Link href={`/show/${id}`} passHref>
        <a>
          <Title id={id} />
        </a>
      </Link>
      <About length={50} id={id} />
      <Price id={id} />
      <HR pt={0} />
      <Flex>
        <FlexItem>
          <Height id={id} />
          <Age id={id} />
        </FlexItem>
        <FlexItem
          shrink
          overrides={{
            justifyContent: 'flex-end',
            paddingRight: '15px'
          }}
        >
          <Go id={id} />
        </FlexItem>
      </Flex>
      <HR />

      <Flex>
        <FlexItem
          overrides={{
            flex: '1'
          }}
        >
          <Avatar id={id} />
        </FlexItem>
        <FlexItem
          overrides={{
            flex: '4'
          }}
        >
          <Name id={id} />
        </FlexItem>
      </Flex>
    </Card>
  )
}

export default Standard
