import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'baseui/skeleton'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import isNil from 'lodash/isNil'

import { useHorseAge } from '@hooks/Horse/useHorseAge'

const Age = ({ id }) => {
  const { isLoading, data: { age } = {} } = useHorseAge(id)
  const [css, theme] = useStyletron()

  if (isLoading)
    return (
      <Block marginBottom="scale100">
        <Skeleton height="24px" width="50%" animation />
      </Block>
    )

  if (isNil(age?.years))
    return (
      <Block marginBottom="scale100">
        <Skeleton height="24px" width="50%" />
      </Block>
    )

  return <div>{age?.years} years old</div>
}

Age.propTypes = {
  id: PropTypes.number.isRequired
}

export default Age
