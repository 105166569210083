import React from 'react'
import PropTypes from 'prop-types'
import truncate from 'lodash/truncate'
import { StyledBody } from 'baseui/card'
import { Skeleton } from 'baseui/skeleton'
import { useStyletron } from 'baseui'
import isNull from 'lodash/isNull'

import { useHorseAbout } from '@hooks/Horse/useHorseAbout'

const About = ({ id, length }) => {
  const { isLoading, isError, error, isSuccess, data } = useHorseAbout(id)
  const [css, theme] = useStyletron()

  if (isLoading)
    return (
      <div className={css({ marginBottom: theme.sizing.scale500 })}>
        <Skeleton height="50px" width="100%" rows={2} animation />
      </div>
    )

  if (isNull(data?.about))
    return (
      <div className={css({ marginBottom: theme.sizing.scale500 })}>
        <Skeleton height="50px" width="100%" />
      </div>
    )

  return (
    <StyledBody>
      <div className={css({ whiteSpace: 'pre-wrap' })}>
        {truncate(data?.about, {
          length
        })}
      </div>
    </StyledBody>
  )
}

About.propTypes = {
  id: PropTypes.number.isRequired,
  length: PropTypes.number
}

About.defaultProps = {
  length: 100
}

export default About
