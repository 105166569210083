import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'baseui/skeleton'
import isNull from 'lodash/isNull'
import { Block } from 'baseui/block'

import { H3 as Heading } from '@global/Heading'
import { formatFloatingPointNumber } from '@src/lib/formatNumber'
import { useHorsePrice } from '@hooks/Horse/useHorsePrice'

const Price = ({ id }) => {
  const { isLoading, isError, error, isSuccess, data } = useHorsePrice(id)

  if (isLoading) return <Skeleton height="45px" width="150px" animation />
  if (isNull(data?.price))
    return (
      <Block marginBottom="scale600">
        <Skeleton height="45px" width="150px" />
      </Block>
    )

  return <Heading>{`$${formatFloatingPointNumber(data?.price?.toString() || '', 2)}`}</Heading>
}

Price.propTypes = {
  id: PropTypes.number.isRequired
}

export default Price
